import * as React from 'react';
import { tokens } from '@fluentui/react-theme';
import { makeStyles, useId, Button, Subtitle2, Divider } from "@fluentui/react-components";
import { Dialog, DialogTrigger, DialogSurface, DialogTitle, DialogContent, DialogBody, DialogActions, Input, Field } from "@fluentui/react-components";
import { HeadsetVrRegular, SignOutRegular, LockOpenRegular, LockClosed20Regular } from '@fluentui/react-icons';
import { Spinner } from "@fluentui/react-components";
import { webLightTheme } from '@fluentui/react-components';
import { useNavigate } from "react-router-dom";

export const Header = () => {
    const usernameId = useId('input-username');
    const passwordId = useId('input-password');
    const [open, setOpen] = React.useState(false);
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [processing, setProcessing] = React.useState(false);
    const [authenticated, setAuthenticated] = React.useState(false);
    const [error, setError] = React.useState(false);
    const navigate = useNavigate();

    const handleSignOut = () => {
        setError(false);
        setProcessing(false);
        setAuthenticated(false);
        navigate("/");
    }
    const handleSubmit = (ev: React.FormEvent) => {
        console.log("handleSubmit");
        ev.preventDefault();
        setProcessing(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: username,
                password: password
            })
        };
        fetch('https://ekhi.online/backend/api/login', requestOptions)
            .then(async response => {
                const data = await response.json();
                if (data.login) {
                    setError(false);
                    setUsername("");
                    setOpen(false);
                    setAuthenticated(true);
                    navigate("/dashboard");
                }
                else {
                    setError(true);
                }
            })
            .catch(error => {
                setError(true);
            }).finally(() => {
                setProcessing(false);
                setPassword("");
            });
    };
    return <>
        <header className="header">
            <div className='header-inner'>
                <div className="logo-box">
                    <HeadsetVrRegular fontSize={32} />
                    <Subtitle2>Project EKHI</Subtitle2>
                </div>
                <div>
                    {!authenticated &&
                        <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button icon={<LockClosed20Regular />}>Sign in</Button>
                            </DialogTrigger>
                            <DialogSurface aria-describedby={undefined} className="sign-in-dialog">
                                {processing && <Spinner size="small" label="Logging in..." style={{ background: webLightTheme.colorNeutralBackground1, padding: '10px' }} />}
                                <form onSubmit={handleSubmit} className={processing ? "displayNone" : ""}>
                                    <DialogBody>
                                        <DialogTitle>Sign in to EKHI Control Panel</DialogTitle>
                                        <DialogContent>
                                            <Field label="Username" >
                                                <Input type="text" id={usernameId} name="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                            </Field>
                                            <Field label="Password" validationMessage={error ? "Invalid username or password." : ""} validationState={error ? "error" : undefined} >
                                                <Input type="password" id={passwordId} name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                            </Field>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button icon={<LockOpenRegular />} type="submit" appearance="primary">Sign in</Button>
                                        </DialogActions>
                                        <DialogActions position="start">
                                            <DialogTrigger disableButtonEnhancement>
                                                <Button appearance="secondary">Close</Button>
                                            </DialogTrigger>
                                        </DialogActions>
                                    </DialogBody>
                                </form>
                            </DialogSurface>
                        </Dialog>}

                    {authenticated &&
                        <Dialog>
                            <DialogTrigger disableButtonEnhancement>
                                <Button icon={<SignOutRegular />}>Sign out</Button>
                            </DialogTrigger>
                            <DialogSurface>
                                <DialogBody>
                                    <DialogTitle>Sign out</DialogTitle>
                                    <DialogContent>
                                        Do you really want to sign out?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button appearance="primary" onClick={handleSignOut}>Yes</Button>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button appearance="secondary">No</Button>
                                        </DialogTrigger>
                                    </DialogActions>
                                </DialogBody>
                            </DialogSurface>
                        </Dialog>
                    }
                </div>
            </div>
            <Divider appearance='subtle' />
        </header>
    </>;
};
import { Button, Text, useScrollbarWidth, useFluent, Tooltip, TableBody, TableCell, TableRow, Table, TableHeader, TableHeaderCell, TableCellLayout, useTableFeatures, TableCellActions } from "@fluentui/react-components";
import * as React from 'react';
import { FixedSizeList as List } from 'react-window';
import { ChatFilled, ChatWarningFilled, ChatDismissFilled, CopyRegular } from '@fluentui/react-icons';

export const Console = () => {
    const [logs, setLogs] = React.useState([]);

    React.useEffect(() => {
        const timer = setInterval(() => {
            fetch('https://ekhi.online/backend/api/logs/').then(
                result => {
                    return result.json();
                }).then(data => {
                    setLogs(data);
                });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const {
        targetDocument
    } = useFluent();
    const scrollbarWidth = useScrollbarWidth({
        targetDocument
    });

    const getIcon = (type) => {
        switch (type) {
            case 'error':
                return <ChatDismissFilled color='red' />;
            case 'warning':
                return <ChatWarningFilled color='orange' />;
            case 'info':
                return <ChatFilled color='green' />;
        }
        return <ChatFilled />;
    };
    const getType = (type) => {
        switch (type) {
            case 'error':
                return 'Error';
            case 'warning':
                return 'Warning';
            case 'info':
                return 'Info';
        }
        return 'n/a';
    };
    return <>
        <Table noNativeElements aria-label="Table with selection" aria-rowcount={logs.length}>
            <TableHeader>
                <TableRow aria-rowindex={1}>
                    <TableHeaderCell style={{ flexGrow: '3' }}>Type</TableHeaderCell>
                    <TableHeaderCell style={{ flexGrow: '5' }}>Timestamp</TableHeaderCell>
                    <TableHeaderCell>Tag</TableHeaderCell>
                    <TableHeaderCell style={{ flexGrow: '18' }}>Message</TableHeaderCell>
                    <div role="presentation" style={{
                        width: scrollbarWidth
                    }} />
                </TableRow>
            </TableHeader>
            <TableBody>
                <List height={610} itemCount={logs.length} itemSize={45} width="100%" itemData={logs}>
                    {({
                        index,
                        style,
                        data
                    }) => {
                        return <>
                            <TableRow aria-rowindex={index + 2} style={style} key={data[index].type}>
                                <TableCell style={{ flexGrow: '3' }}>
                                    <TableCellLayout media={getIcon(data[index].type)}>
                                        {getType(data[index].type)}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell style={{ flexGrow: '5' }}>{data[index].timestamp}</TableCell>
                                <TableCell>{data[index].tag}</TableCell>
                                <TableCell style={{ flexGrow: '18' }}>
                                    <Tooltip withArrow content={<><Text weight="semibold">{data[index].title}</Text><br />
                                        <Text>{data[index].message}</Text></>} relationship="label">
                                        <TableCellLayout style={{ overflow: 'hidden' }}>
                                            <Text weight="semibold">{data[index].title}</Text><br />
                                            <Text style={{ whiteSpace: 'nowrap' }}>{data[index].message}</Text>
                                        </TableCellLayout>
                                    </Tooltip>
                                    <TableCellActions>
                                        <Button icon={<CopyRegular />} appearance="subtle" />
                                    </TableCellActions>
                                </TableCell>
                            </TableRow>
                        </>;
                    }}
                </List>
            </TableBody>
        </Table >
    </>
};
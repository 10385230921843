import * as React from 'react';
import { useId, Button, Text, Subtitle2, Divider, Card, CardHeader, CardPreview, CardFooter } from "@fluentui/react-components";
import { MailRegular, GlobeRegular, Info20Regular, ContactCard20Regular } from '@fluentui/react-icons';

export const Home = () => {
    const usernameId = useId('input-username');
    const passwordId = useId('input-password');
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        if (open && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [open]);
    return <>
        <main className="main-home">
            <div className="card-holder">
                <Card className="home-card" size='large'>
                    <CardPreview>
                        <img src="/resources/tom.jpg" />
                    </CardPreview>
                    <CardHeader image={<Info20Regular />} header={<Text weight="semibold">About Project EKHI</Text>} />
                    <Text align="justify">
                        The goal of this project is to give therapists a set of
                        tools utilizing virtual reality and modern web technologies.
                        Patients can be guided through interactive experience
                        tailored to their needs and the therapist is able to adjust
                        the experience parameters in real time, making it possible
                        to react to momentary state of the patient. If you are
                        interested in more information, contact us, we will be happy
                        to organize a demo!
                    </Text>
                    {/*<CardFooter><Button icon={<ShareRegular />}>Share</Button></CardFooter>*/}
                </Card>
                <Card className="home-card" size='large'>
                    <CardPreview>
                        <img src="/resources/fit.jpg" />
                    </CardPreview>
                    <CardHeader image={<ContactCard20Regular />} header={<Text weight="semibold">Research &amp; Development</Text>} />
                    <div className="address">
                        <Subtitle2>Faculty of Information Technology</Subtitle2>
                        <Text weight="semibold">Brno University of Technology</Text>
                        <Text>Bozetechova 2/1</Text>
                        <Text>612 00 Brno</Text>
                        <Text>Czech Republic</Text>
                    </div>
                    <CardFooter>
                        <Button icon={<MailRegular />} onClick={() => window.open('mailto:isvec@fit.vutbr.cz')}>isvec@fit.vutbr.cz</Button>
                        <Button icon={<GlobeRegular />} onClick={() => window.open('https://www.fit.vutbr.cz')}>fit.vutbr.cz</Button>
                    </CardFooter>
                </Card>
                <Card className="home-card" size='large'>
                    <CardPreview>
                        <img src="/resources/muni.jpg" />
                    </CardPreview>
                    <CardHeader image={<ContactCard20Regular />} header={<Text weight="semibold">Medical &amp; Research Supervision</Text>} />
                    <div className="address">
                        <Subtitle2>University Hospital Brno</Subtitle2>
                        <Text weight="semibold">Department of Psychiatry</Text>
                        <Text>Jihlavská 340/20, Pavilion G</Text>
                        <Text>625 00 Brno</Text>
                        <Text>Czech Republic</Text>
                    </div>
                    <CardFooter>
                        <Button icon={<MailRegular />} onClick={() => window.open('mailto:elis.bartecku@med.muni.cz')}>elis.bartecku@med.muni.cz</Button>
                        <Button icon={<GlobeRegular />} onClick={() => window.open('https://psychiatrie.med.muni.cz')}>psychiatrie.med.muni.cz</Button>
                    </CardFooter>
                </Card>
                <Divider alignContent="center" appearance='subtle'>Copyright © {(new Date().getFullYear())} Faculty of Information Technology, BUT</Divider>
            </div>
        </main>
    </>;
};
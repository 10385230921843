import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { makeStyles, shorthands, Text, Tab, TabList } from "@fluentui/react-components";
import {
    PersonSettingsFilled, InfoFilled, TimelineRegular, TimelineFilled, PersonNoteRegular,
    PersonAccountsFilled, SettingsRegular, PersonSettingsRegular, InfoRegular,
    BoardSplitRegular, PersonNoteFilled, PersonAccountsRegular, SettingsFilled,
    bundleIcon, BoardSplitFilled
} from "@fluentui/react-icons";

const TherapistsIcon = bundleIcon(PersonAccountsFilled, PersonAccountsRegular);
const SettingsIcon = bundleIcon(SettingsFilled, SettingsRegular);
const BoardSplitIcon = bundleIcon(BoardSplitFilled, BoardSplitRegular);
const AccountIcon = bundleIcon(PersonSettingsFilled, PersonSettingsRegular);
const InfoIcon = bundleIcon(InfoFilled, InfoRegular);
const PatientsIcon = bundleIcon(PersonNoteFilled, PersonNoteRegular);
const TimelineIcon = bundleIcon(TimelineFilled, TimelineRegular);

const tabs = [
    {
        id: "control-panel",
        name: "Control Panel",
        icon: null
    },
    {
        id: "dashboard",
        name: "Dashboard",
        icon: <BoardSplitIcon />
    },
    {
        id: "schedules",
        name: "Schedules",
        icon: <TimelineIcon />
    },
    {
        id: "therapists",
        name: "Therapists",
        icon: <TherapistsIcon />
    },
    {
        id: "patients",
        name: "Patients",
        icon: <PatientsIcon />
    },
    {
        id: "settings",
        name: "Settings",
        icon: null
    },
    {
        id: "account",
        name: "Account",
        icon: <AccountIcon />
    },
    {
        id: "preferences",
        name: "Preferences",
        icon: <SettingsIcon />
    },
    {
        id: "about",
        name: "About",
        icon: <InfoIcon />
    }
];


const useStyles = makeStyles({
    tabList: { opacity: '0.85' },
    tabItem: {},
    tabHeader: {
        lineHeight: "32px",
        paddingLeft: "12px",
        paddingTop: "6px"
    },
    customLineColor: {
        paddingTop: '10px',
        fontSize: '13px',
        '::before': {
            ...shorthands.borderColor('rgba(0, 0, 0, 0.06)')
        },
        '::after': {
            ...shorthands.borderColor('rgba(0, 0, 0, 0.06)')
        }
    }
});

export const ControlPanelNavigation = () => {
    const styles = useStyles();
    const [selectedTabId, setSelectedTabId] = React.useState(window.location.pathname);
    const navigate = useNavigate();
    const onTabSelect = (tabId) => {
        setSelectedTabId(tabId);
        navigate("/"+tabId);
    };
    React.useEffect(() => {
        setSelectedTabId(window.location.pathname.substring(1));
     },[]);
    return (
        <>
            <TabList
                className='tabList'
                appearance="subtle"
                vertical
                selectedValue={selectedTabId}
                onTabSelect={(_, d) => {onTabSelect(d.value);}}
            >
                {tabs.map((tab) => {
                    if (tab.icon == null)
                        return (
                            <Text key={tab.name} className={styles.tabHeader} weight="semibold">{tab.name}</Text>
                        );
                    else
                        return (
                            <Tab key={tab.name} className={styles.tabItem} value={tab.id} icon={<span>{tab.icon}</span>}>{tab.name}</Tab>
                        );
                })}
            </TabList>
        </>
    );
};
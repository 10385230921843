import * as React from 'react';
import { Text, Card, CardHeader } from "@fluentui/react-components";
import { Info20Regular } from '@fluentui/react-icons';
import { ControlPanelNavigation } from './control-panel-navigation';

export const About = () => {
    return <>
        <div className='control-panel-layout'>
            <aside className="sidenav">
                <ControlPanelNavigation />
            </aside>
            <div className="main-cards">
                <Card size='large'>
                    <CardHeader image={<Info20Regular />} header={<Text weight="semibold">About EKHI Control Panel</Text>} />
                    <table>
                        <tbody>
                            <tr>
                                <td>Version</td>
                                <td> 0.1a</td>
                            </tr>
                            <tr>
                                <td>Build date</td>
                                <td>TODO: {new Date().toString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            </div>
        </div>
    </>;
};
import * as React from 'react';
import { Select, Switch, Field, Slider } from "@fluentui/react-components";

export const CustomField = (props) => {
    const { property, onChange } = props;
    const [value, setValue] = React.useState(property.value);

    function handleChange(newValue) {
        setValue(newValue);
        onChange(newValue);
    }

    switch (property.type) {
        case 'enum': {
            return (
                <Field label={property.label} orientation="horizontal">
                    <Select
                        value={value}
                        onChange={(event, data) => handleChange(data.value)}
                    >
                        {property.values.map((option, index) => (
                            <option key={index} value={index}>{option}</option>
                        ))}
                    </Select>
                </Field>
            );
        }
        case 'bool': {
            return (
                <Field label={property.label} orientation="horizontal">
                    <Switch
                        checked={value}
                        onChange={(event) => handleChange(event.target.checked)}
                    />
                </Field>
            );
        }
        case 'int': {
            return (
                <Field label={`${property.label} (${value})`} orientation="horizontal">
                    <Slider

                        min={property.min}
                        max={property.max}
                        value={value}
                        onChange={(event, data) => handleChange(data.value)}
                    />
                </Field>
            );
        }
        case 'float': {
            return (
                <Field label={`${property.label} (${value})`} orientation="horizontal">
                    <Slider
                        min={property.min}
                        max={property.max}
                        value={value}
                        onChange={(event, data) => handleChange(data.value)}
                    />
                </Field>
            );
        }
        default: {
            return (
                <div>undefined input type</div>
            );
        }
    }
};
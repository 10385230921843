import * as React from 'react';
import { Text, Card, CardHeader } from "@fluentui/react-components";
import { Settings20Regular } from '@fluentui/react-icons';
import { ControlPanelNavigation } from './control-panel-navigation';

export const Preferences = () => {
    return <>
        <div className='control-panel-layout'>
            <aside className="sidenav">
                <ControlPanelNavigation />
            </aside>
            <div className="main-cards">
                <Card size='large'>
                    <CardHeader image={<Settings20Regular />} header={<Text weight="semibold">Preferences</Text>} />
                </Card>
            </div>
        </div>
    </>;
};
import * as React from 'react';
import { Text, Button, Spinner, Subtitle2, Avatar, Divider, Caption1, Tab, TabList, SelectTabData, SelectTabEvent } from "@fluentui/react-components";
import { Dialog, DialogTrigger, DialogSurface, DialogTitle, DialogContent, DialogBody, DialogActions, Card, CardHeader, CardPreview, Dropdown, Option } from "@fluentui/react-components";
import { HeadsetVrRegular, HeartPulse20Regular, TextBulletListSquareWarning20Regular, ClipboardTextEditRegular, SignOutRegular, TimelineRegular } from '@fluentui/react-icons';
import { ScenarioConfiguration } from './scenario-configuration';
import { SchedulerConfiguration } from './scheduler-configuration';
import { ControlPanelNavigation } from './control-panel-navigation';
import { HeartBeatChart } from './heart-beat-chart';
import { Console } from './console';
import { webLightTheme } from '@fluentui/react-components';

export const Dashboard = () => {
    const [selectedValue, setSelectedValue] =
        React.useState("manual");

    const onTabSelect = (event, data) => {
        setSelectedValue(data.value);
    };
    const [isConnected, setConnected] = React.useState(false);
    React.useEffect(() => {
        const timer = setInterval(() => {
            fetch('https://ekhi.online/backend/api/configurations/1?vr_access_timeout').then(
                result => {
                    return result.json();
                }).then(data => {
                    setConnected(data.vr_access_timeout < 3);
                })
                .catch(error => {
                    setConnected(false); // TODO
                });
        }, 1000);
        return () => clearInterval(timer);
    });
    const loadImage = (e) => {
        e.target.src = "https://ekhi.online/backend/api/screenshots/1?screenshot&" + Math.random();
    }

    return <>
        <div className='control-panel-layout'>
            <aside className="sidenav">
                <ControlPanelNavigation />
            </aside>
            <main className="main">
                <div className="main-cards">
                    <Card size='large' className="card">
                        <CardHeader image={<Avatar name="John Dow" badge={{ status: isConnected ? 'available' : 'offline' }} />} header={<Text weight="semibold">John Doe</Text>} description={<Caption1>{isConnected ? 'Connected to the remote therapy session.' : 'Disonnected from the remote therapy session.'}</Caption1>} />
                        <CardPreview>
                            <div style={{ width: '100%', aspectRatio: '460 / 240', background: webLightTheme.colorNeutralBackground2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {isConnected && <img src="https://ekhi.online/backend/api/screenshots/1?screenshot" width="100%" height="100%" onLoad={loadImage} onError={loadImage} />}
                                {!isConnected && <Spinner size="small" label="Stream not available..." />}
                            </div>
                        </CardPreview>
                    </Card>
                    <Card size='large' className="card">
                        <CardHeader style={{ marginLeft: '-12px', marginTop: '-12px' }} header={
                            <TabList defaultSelectedValue="manual" selectedValue={selectedValue} onTabSelect={onTabSelect} >
                                <Tab value="manual" icon={<ClipboardTextEditRegular />}>
                                    Manually controlled therapy
                                </Tab>
                                <Tab value="scheduler" icon={<TimelineRegular />}>
                                    Scheduler-controlled therapy
                                </Tab>
                            </TabList>
                        } />

                        <div style={{ height: '385px', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {selectedValue === "manual" && <ScenarioConfiguration />}
                            {selectedValue === "scheduler" && <SchedulerConfiguration />}
                        </div>
                    </Card>
                    <Card size='large' className="card" style={{ maxHeight: '262px', paddingBottom: '50px' }}>
                        <CardHeader image={<HeartPulse20Regular />} header={<Text weight="semibold">Heart rate [bpm - beats per minute]</Text>} />
                        <HeartBeatChart />
                    </Card>
                    <Card size='large' className="card" style={{ height: '100%' }}>
                        <CardHeader image={<TextBulletListSquareWarning20Regular />} header={<Text weight="semibold">Console</Text>} />
                        <Console />
                    </Card>
                </div>
            </main>
        </div>
    </>;
};
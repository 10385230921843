import React from "react";
import { Chart, Line } from "react-chartjs-2";
import { Spinner } from "@fluentui/react-components";
import { webLightTheme } from '@fluentui/react-components';
import "chartjs-plugin-streaming";
import moment from "moment";


export const HeartBeatChart = () => {
    const [isConnected, setIsConnected] = React.useState(false);
    let heartbeat = null;
    let lastHeartBeatId = 0;
    let first = true;
    React.useEffect(() => {
        const timer = setInterval(() => {
            fetch('https://ekhi.online/backend/api/logs/?heartbeat&' + Math.random()).then(
                result => {
                    return result.json();
                }).then(data => {
                    heartbeat = data;
                });
        }, 1000);
        return () => clearInterval(timer);
    });

    Chart.defaults.global.defaultFontFamily = "'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serifFont family fontFamilyBase";
    Chart.defaults.global.defaultFontColor = webLightTheme.colorNeutralForeground1;
    const data = {
        datasets: [
            {
                label: "Heart rate [bpm - beats per minute]",
                borderColor: "red",
                fill: false,
                lineTension: 0.25,
                borderWidth: 1,
                pointStyle: 'circle',
                data: []
            }
        ]
    };
    const options = {
        plugins: {
            streaming: {
                frameRate: 15
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                tension: 0.5
            }
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [
                {
                    type: "realtime",
                    realtime: {
                        onRefresh: function (chart) {
                            if (heartbeat == null)
                                return;
                            if (lastHeartBeatId != heartbeat.id) {
                                lastHeartBeatId = heartbeat.id;
                                if (!first) {
                                    chart.data.datasets[0].data.push({
                                        x: moment(),
                                        y: parseFloat(heartbeat.message)
                                    });
                                    setIsConnected(true);
                                }
                                first = false;
                            }
                        },
                        delay: 1000,
                        duration: 120000
                    },
                    ticks: {
                        autoSkip: true,
                        callback: function (value) {
                            return moment(value, "HH:mm:ss A").format("HH:mm:ss");
                        }
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        suggestedMin: 60,
                        suggestedMax: 120,
                        callback: function (value, index, values) {
                            return value + ' bpm';
                        }
                    }
                }
            ]
        }
    };

    return (
        <>
            {!isConnected && <div style={{ position: 'absolute', left: '0', right: '0', top: '0', bottom: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner size="small" label="Data not available..." style={{ background: webLightTheme.colorNeutralBackground1, padding: '10px' }} />
            </div>}
            <Line data={data} options={options} />
        </>
    );
};

import React from 'react';
import { FluentProvider, webLightTheme, webDarkTheme } from '@fluentui/react-components';
import { Home } from './home';
import { Dashboard } from './control-panel/dashboard';
import { Scheduler } from './control-panel/scheduler';
import { Therapists } from './control-panel/therapists';
import { Patients } from './control-panel/patients';
import { Account } from './control-panel/account';
import { Preferences } from './control-panel/preferences';
import { About } from './control-panel/about';
import { Header } from './header';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import { Schedules } from './control-panel/schedules';

export const App: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <FluentProvider theme={webLightTheme} className="root">
        <div className="base-layout">
          <Header />
          <Routes>
            <Route index element={<Home />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="scheduler" element={<Scheduler />} />
            <Route path="scheduler/:id" element={<Scheduler />} />
            <Route path="schedules" element={<Schedules />} />
            <Route path="therapists" element={<Therapists />} />
            <Route path="patients" element={<Patients />} />
            <Route path="account" element={<Account />} />
            <Route path="preferences" element={<Preferences />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </div>
      </FluentProvider>
    </BrowserRouter>
  );
};

import * as React from 'react';
import { Field, Textarea, Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, MenuButton } from "@fluentui/react-components";
import { PlayRegular, TimelineRegular, OpenFilled } from '@fluentui/react-icons';

let config = null;
let v = 0;

export const SchedulerConfiguration = () => {

    const [schedule, setSchedule] = React.useState('');

    function RunSchedule() {
        const url = 'https://ekhi.online/backend/scheduler.php';

        const formData = new URLSearchParams();
        formData.append('schedule', schedule);

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        }).then(data => {
            console.log(data);
        });
        handleStart();
    }

    function handleScheduleChange(event) {
        setSchedule(event.target.value);
    };

    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);
    const [isRunning, setIsRunning] = React.useState(false);

    React.useEffect(() => {
        let timerInterval = null;

        if (isRunning) {
            timerInterval = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 59) {
                        setMinutes((prevMinutes) => prevMinutes + 1);
                        return 0;
                    } else {
                        return prevSeconds + 1;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(timerInterval);
    }, [isRunning]);

    const [schedules, setScheudles] = React.useState([]);
    React.useEffect(() => {
            fetch('https://ekhi.online/backend/api/schedules/').then(
                result => {
                    return result.json();
                }).then(data => {
                    console.log(data);
                    setScheudles(data);
                });
    }, []);

    const handleStart = () => {
        handleRestart();
        if (!isRunning) {
            setIsRunning(true);
        }
    };

    const handleRestart = () => {
        setMinutes(0);
        setSeconds(0);
    };

    const formatTime = (time) => {
        return `${time < 10 ? '0' : ''}${time}`;
    };

    const LoadSchedule = ({ props }) => {
        return <>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton>Load schedule from database ...</MenuButton>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        {props.schedules && props.schedules.map((item) => (<MenuItem key={item.id} onClick={() => setSchedule(item.schedule)}>{item.name}</MenuItem>))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>;
    };
    return <>
        {!isRunning && <LoadSchedule props={{ schedules: schedules }}/>}
        <Field label="JSON schedule" >
            <Textarea name="text" resize="vertical" value={schedule} onChange={handleScheduleChange} />
        </Field><Button icon={<PlayRegular />} onClick={RunSchedule}>Run</Button>
        Elapsed time since start: {formatTime(minutes)}m:{formatTime(seconds)}s
    </>;
};
import * as React from 'react';
import { Spinner } from "@fluentui/react-components";
import { CustomField } from '../field';

let config = null;
let v = 0;

export const ScenarioConfiguration = () => {
    const [configuration, setConfiguration] = React.useState(null);
    const [scenarios, setScenarios] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        fetch('https://ekhi.online/resources/configuration1.json').then(
            result => {
                return result.json();
            }).then(data => {
                config = data;
                setScenarios(data.scenarios.map((item) => { return item.name; }));
                setIsLoading(false);
            });
            SaveConfig(null);
    }, []);

    function handleChange(i, value) {
        let x = configuration.map((config, index) => {
            return index === i ? { ...config, value: value, } : config;
        });
        setConfiguration(x)
        SaveConfig(x);
    }
    function serialize(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      }
      
    function SaveConfig(configuration){
        let jsonConfig = {scenario:v-1};

        if(configuration){
            configuration.forEach(element => {
                jsonConfig[element.name] = element.value;
            });
        }

        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                configuration: JSON.stringify(jsonConfig)
            })
        };

        fetch('https://ekhi.online/backend/api/configurations/1', requestOptions)
        .then(async response => {
            console.log(serialize(response)); // TODO
        })
        .catch(error => {
            console.log(serialize(error)); // TODO
        });
    }

    function handleScenarioChange(value) {
        v = value;
        let x = null;
        setConfiguration(null);
        if (value > 0) {
            let c = config.scenarios[value - 1].properties;
            x = c;
            setConfiguration(c);
        }
        SaveConfig(x);
    }

    return <>
        {isLoading && <Spinner size="small" label="Loading..." />}
        {scenarios.length > 0 && <CustomField property={{ type: 'enum', label: 'Scenario', values: [' - none - ', ...scenarios] }} onChange={(e) => { handleScenarioChange(e) }} />}
        {configuration && configuration.map((property, index) => (<CustomField key={v + property.name} property={property} onChange={(e) => handleChange(index, e)} />))}
    </>;
};
import * as React from 'react';
import { Text, Spinner, Popover, PopoverTrigger, PopoverSurface, Button, Caption1, Input, SpinButton, Textarea, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, MenuDivider, Badge, Card, CardHeader, Field } from "@fluentui/react-components";
import { Timeline20Regular, AddSquare24Regular, MoreHorizontal20Filled, Braces20Regular, Timer20Regular, Chat20Regular, AppsList20Regular, SaveRegular } from '@fluentui/react-icons';
import { ControlPanelNavigation } from './control-panel-navigation';
import { CustomField } from '../field';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";



let config = null;
export const Scheduler = () => {
    const [items, setItems] = React.useState([]);
    const [schedule, setSchedule] = React.useState([]);
    const [scenarios, setScenarios] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [name, setName] = React.useState('');
    const [nameValidation, setNameValidation] = React.useState({});
    const navigate = useNavigate();
    const params = useParams();
    React.useEffect(() => {
        fetch('https://ekhi.online/resources/configuration1.json').then(
            result => {
                return result.json();
            }).then(data => {
                config = data;
                console.log(data);
                setScenarios(data.scenarios.map((item) => { return item.name; }));
                setIsLoading(false);
            });
    }, []);

    React.useEffect(() => {
        if (params.id)
            fetch('https://ekhi.online/backend/api/schedules/' + params.id).then(
                result => {
                    return result.json();
                }).then(data => {
                    try {
                        setItems(JSON.parse(data.schedule));
                        setName(data.name);
                    } catch (e) {
                        //
                    }
                });
    }, []);

    const DynamicSubMenu = ({ props, onClick }) => {
        const [open, setOpen] = React.useState(false);
        const onOpenChange = (e, data) => {
            setOpen(data.open);
        };

        return (
            <Menu open={open} onOpenChange={onOpenChange}>
                <MenuTrigger disableButtonEnhancement>
                    <MenuItem>
                        {props.scenarios && "Scenarios"}
                        {props.properties && "Properties"}
                    </MenuItem>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {props.scenarios && props.scenarios.map((value, index) => (<MenuItem key={index} onClick={() => addScenario(index)}>{value}</MenuItem>))}
                        {props.properties && props.properties.map((value, index) => (<MenuItem key={value.name} onClick={() => addSubItem(props.subitem, value.name)}>{value.label}</MenuItem>))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    };

    const AddScheduleItem = ({ props }) => {
        return <>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Button icon={<AddSquare24Regular />}></Button>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <DynamicSubMenu props={props}></DynamicSubMenu>
                        <MenuDivider />
                        <MenuItem onClick={() => props.subitem !== undefined ? addSubDelay(props.subitem) : addDelay()}>Delay</MenuItem>
                        <MenuItem onClick={() => props.subitem !== undefined ? addSubMessage(props.subitem) : addMessage()}>Message</MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>;
    };

    const validateName = (name) => {
        if (name) {
            setNameValidation({});
            return true;
        }

        setNameValidation({ message: 'Name is required.', state: 'error' });
        return false;
    }

    const save = () => {
        if (!validateName(name))
            return false;

        console.log(JSON.stringify(items, null, 4));
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                schedule: JSON.stringify(items, null, 4),
                name: name
            })
        };

        fetch('https://ekhi.online/backend/api/schedules/' + (params.id !== undefined ? params.id : ''), requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.id !== undefined) {
                    navigate("/scheduler/" + data.id);
                }
            });
    }

    const getProperty = (scenario, name, value) => {
        var property = { ...config.scenarios[scenario].properties.find(property => property.name === name) };
        property.value = value;
        property.name = "value";
        return property;
    }

    const addScenario = (id) => {
        setItems([
            ...items,
            { scenario: { id: id }, subItems: [] }
        ]);
    };

    const addDelay = () => {
        setItems([...items, { delay: 0 }]);
    };

    const addMessage = () => {
        setItems([...items, { message: { text: "", delay: 0 } }]);
    };

    const removeItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
    };

    const addSubItem = (parentIndex, subItemName, subItemType) => {
        const newItems = [...items];
        newItems[parentIndex].subItems.push({ name: subItemName, type: subItemType, value: "" });
        setItems(newItems);
    };

    const addSubMessage = (parentIndex) => {
        const newItems = [...items];
        newItems[parentIndex].subItems.push({ message: { text: "", delay: 0 } });
        setItems(newItems);
    };

    const addSubDelay = (parentIndex) => {
        const newItems = [...items];
        newItems[parentIndex].subItems.push({ delay: 0 });
        setItems(newItems);
    };

    const removeSubItem = (parentIndex, subIndex) => {
        const newItems = [...items];
        newItems[parentIndex].subItems.splice(subIndex, 1);
        setItems(newItems);
    };

    const moveItemUp = (index) => {
        if (index === 0) return;
        const newItems = [...items];
        [newItems[index - 1], newItems[index]] = [
            newItems[index],
            newItems[index - 1]
        ];
        setItems(newItems);
    };

    const moveItemDown = (index) => {
        if (index === items.length - 1) return;
        const newItems = [...items];
        [newItems[index], newItems[index + 1]] = [
            newItems[index + 1],
            newItems[index]
        ];
        setItems(newItems);
    };

    const moveSubItemUp = (parentIndex, subIndex) => {
        if (subIndex === 0) return;
        const newItems = [...items];
        [
            newItems[parentIndex].subItems[subIndex - 1],
            newItems[parentIndex].subItems[subIndex]
        ] = [
                newItems[parentIndex].subItems[subIndex],
                newItems[parentIndex].subItems[subIndex - 1]
            ];
        setItems(newItems);
    };

    const moveSubItemDown = (parentIndex, subIndex) => {
        if (subIndex === items[parentIndex].subItems.length - 1) return;
        const newItems = [...items];
        [
            newItems[parentIndex].subItems[subIndex],
            newItems[parentIndex].subItems[subIndex + 1]
        ] = [
                newItems[parentIndex].subItems[subIndex + 1],
                newItems[parentIndex].subItems[subIndex]
            ];
        setItems(newItems);
    };

    const handleInputChange = (event, index, subIndex = null) => {
        const { name, value } = event.target;
        const newItems = [...items];

        if (subIndex === null) {
            newItems[index][name] = value;
        } else {
            newItems[index].subItems[subIndex][name] = value;
        }
        setItems(newItems);
    };

    const handlePropertyChange = (index, subIndex, name, value) => {
        const newItems = [...items];
        newItems[index].subItems[subIndex][name] = value;
        setItems(newItems);
    };

    const handleMessageChange = (event, index, subIndex = null) => {
        const { name, value } = event.target;
        const newItems = [...items];
        if (subIndex === null) {
            newItems[index].message[name] = value;
        } else {
            newItems[index].subItems[subIndex].message[name] = value;
        }
        setItems(newItems);
    };

    const getItemImage = (item) => {
        if (item.delay !== undefined) return <Timer20Regular />;
        if (item.message !== undefined) return <Chat20Regular />;
        return <AppsList20Regular />;
    }

    const getTime = (index) => {
        var time = 0;
        items.every((item, i) => {
            if (i == index)
                return false;
            if (item.delay !== undefined)
                time += parseInt(item.delay);
            if (item.message !== undefined)
                time += parseInt(item.message.delay);
            if (item.subItems !== undefined)
                time += getLength(i);
            return true;
        });
        return time;
    }
    function fmtMSS(s) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

    const getLength = (index) => {
        var length = 0;
        if (items[index].message !== undefined) return parseInt(items[index].message.delay);
        if (items[index].delay !== undefined) return parseInt(items[index].delay);
        items[index].subItems.forEach((item, i) => {
            if (item.delay !== undefined)
                length += parseInt(item.delay);
            if (item.message !== undefined)
                length += parseInt(item.message.delay);
        });
        return length;
    }

    return (
        <>
            <div className='control-panel-layout'>
                <aside className="sidenav">
                    <ControlPanelNavigation />
                </aside>
                <div className="main-cards">
                    <Card size='large' className="card">
                        <CardHeader image={<Timeline20Regular />} header={<Text weight="semibold">Schenduler</Text>} />
                        {items.map((item, index) => (
                            <div key={index}>
                                <div>
                                    <Card size="small" role="listitem">
                                        <CardHeader
                                            image={
                                                getItemImage(item)
                                            }
                                            header={
                                                <>
                                                    <Text weight="semibold">
                                                        {item.scenario !== undefined && scenarios[item.scenario.id]}
                                                        {item.delay !== undefined && "Delay"}
                                                        {item.message !== undefined && "Message"}
                                                    </Text>
                                                    <div style={{ float: "right" }}>
                                                        <Badge color="brand" shape="rounded" appearance="tint">Time: {fmtMSS(getTime(index))}</Badge> <Badge color="success" shape="rounded" appearance="tint">Length: {fmtMSS(getLength(index))}</Badge>
                                                    </div>
                                                </>

                                            }
                                            action={
                                                <>
                                                    <Menu>
                                                        <MenuTrigger disableButtonEnhancement>
                                                            <Button
                                                                appearance="transparent"
                                                                icon={<MoreHorizontal20Filled />}
                                                            />
                                                        </MenuTrigger>
                                                        <MenuPopover>
                                                            <MenuList>
                                                                <MenuItem onClick={() => moveItemUp(index)}>Move Up</MenuItem>
                                                                <MenuItem onClick={() => moveItemDown(index)}>Move Down</MenuItem>
                                                                <MenuDivider />
                                                                <MenuItem onClick={() => removeItem(index)}>Delete</MenuItem>
                                                            </MenuList>
                                                        </MenuPopover>
                                                    </Menu>
                                                </>
                                            }
                                        />
                                        {item.message !== undefined && (
                                            <Card key={index + "_"} size='small' className='ScheduleItemCard'>
                                                <Textarea label="Message" orientation='horizontal' name="text" value={item.message.text} onChange={(e) => handleMessageChange(e, index)} />
                                                <SpinButton orientation='horizontal' label='Delay [s]' min='0' name="delay" value={item.message.delay} onChange={(e) => handleMessageChange(e, index)} />
                                            </Card>
                                        )}
                                        {item.delay !== undefined && (
                                            <Card key={index + "_"} size='small' className='ScheduleItemCard'>
                                                <SpinButton orientation='horizontal' label='Delay [s]' min='0' name="delay" value={item.delay} onChange={(e) => handleInputChange(e, index)} />
                                            </Card>
                                        )}
                                        {item.scenario !== undefined && (
                                            <>
                                                {item.subItems &&
                                                    item.subItems.map((subItem, subIndex) => (
                                                        <Card key={index + "_" + subIndex} size='small' className='ScheduleItemCard'>
                                                            <div className='ScheduleItem'>
                                                                <div className='Property'>
                                                                    {subItem.message !== undefined && (
                                                                        <>
                                                                            <Field label="Message" orientation='horizontal'>
                                                                                <Textarea name="text" value={subItem.message.text} onChange={(e) => handleMessageChange(e, index, subIndex)} />
                                                                            </Field>
                                                                            <Field label='Delay [s]' orientation='horizontal'>
                                                                                <SpinButton min='0' name="delay" value={subItem.message.delay} onChange={(e) => handleMessageChange(e, index, subIndex)} />
                                                                            </Field>
                                                                        </>
                                                                    )}
                                                                    {subItem.delay !== undefined && (
                                                                        <Field label='Delay [s]' orientation='horizontal'>
                                                                            <SpinButton min='0' name="delay" value={subItem.delay} onChange={(e) => handleInputChange(e, index, subIndex)} />
                                                                        </Field>
                                                                    )}
                                                                    {subItem.name !== undefined && (
                                                                        <CustomField property={getProperty(item.scenario.id, subItem.name, subItem.value)} onChange={(value) => { handlePropertyChange(index, subIndex, "value", value) }} />
                                                                    )}
                                                                </div>
                                                                <div className='Menu'>
                                                                    <Menu>
                                                                        <MenuTrigger disableButtonEnhancement>
                                                                            <Button
                                                                                appearance="transparent"
                                                                                icon={<MoreHorizontal20Filled />}
                                                                            />
                                                                        </MenuTrigger>
                                                                        <MenuPopover>
                                                                            <MenuList>
                                                                                <MenuItem onClick={() => moveSubItemUp(index, subIndex)}>Move Up</MenuItem>
                                                                                <MenuItem onClick={() => moveSubItemDown(index, subIndex)}>Move Down</MenuItem>
                                                                                <MenuDivider />
                                                                                <MenuItem onClick={() => removeSubItem(index, subIndex)}>Delete</MenuItem>
                                                                            </MenuList>
                                                                        </MenuPopover>
                                                                    </Menu>
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    ))}
                                                <AddScheduleItem props={{ properties: config.scenarios[item.scenario.id].properties, subitem: index }} />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            </div>
                        ))}
                        {scenarios.length > 0 && <AddScheduleItem props={{ scenarios: scenarios }} />}
                        <br />
                    </Card>
                    <Card>
                        <CardHeader image={<Braces20Regular />} header={<Text weight="semibold">JSON Schedule</Text>} />
                        <Field label="Schedule name" validationMessage={nameValidation.message} validationState={nameValidation.state} >
                            <Input type="text" name="name" value={name} required onChange={(ev, data) => { validateName(data.value, setNameValidation); setName(data.value) }} />
                        </Field>
                        <Field label="Generated JSON" >
                            <Textarea name="text" resize="vertical" readOnly placeholder="Generated JSON schedule will be displayed here." value={items.length == 0 ? "" : JSON.stringify(items, null, 4)} />
                        </Field>
                        <Button onClick={() => save()} icon={<SaveRegular />}>Save</Button>
                    </Card>
                </div >
            </div >
        </>
    );
}


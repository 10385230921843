import * as React from 'react';
import { useScrollbarWidth, useFluent, Tooltip } from "@fluentui/react-components";
import { FixedSizeList as List } from 'react-window';
import { 
    TableBody, TableCell, TableRow, Table, TableHeader, TableHeaderCell, TableCellLayout, useTableFeatures, TableCellActions,
    Text, Spinner, Popover, PopoverTrigger, PopoverSurface, Button, Caption1, Divider, SpinButton, Textarea, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, MenuDivider, Badge, Card, CardHeader } from "@fluentui/react-components";
import { Timeline20Regular, AddSquare24Regular, MoreHorizontal20Filled, Braces20Regular, Timer20Regular, Chat20Regular, AppsList20Regular } from '@fluentui/react-icons';
import { ChatFilled, ChatWarningFilled, ChatDismissFilled, CopyRegular, EditRegular, DeleteRegular, AddRegular } from '@fluentui/react-icons';
import { ControlPanelNavigation } from './control-panel-navigation';

import { useNavigate } from "react-router-dom";
let config = null;
export const Schedules = () => {
    const [logs, setLogs] = React.useState([]);
    const navigate = useNavigate();
    React.useEffect(() => {
        const timer = setInterval(() => {
            fetch('https://ekhi.online/backend/api/schedules/').then(
                result => {
                    return result.json();
                }).then(data => {
                    setLogs(data);
                });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const {
        targetDocument
    } = useFluent();
    const scrollbarWidth = useScrollbarWidth({
        targetDocument
    });

    const deleteSchedule = (id) => {
        fetch('https://ekhi.online/backend/api/schedules/'+id, {
          method: 'DELETE'
        })
          .then(response => {
            // Handle the response
            if (response.ok) {
              console.log('POST request successful');
            } else {
              console.log('POST request failed');
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });
      };

    return <>
        <div className='control-panel-layout'>
            <aside className="sidenav">
                <ControlPanelNavigation />
            </aside>
            <div className="main-cards">
                <Card size='large' className="card">
                    <CardHeader image={<Timeline20Regular />} header={<Text weight="semibold">Schedules</Text>} />
                    <Table noNativeElements aria-label="Table with selection" aria-rowcount={logs.length}>
                        <TableHeader>
                            <TableRow aria-rowindex={1}>
                                <TableHeaderCell>#</TableHeaderCell>
                                <TableHeaderCell>Schecule Name</TableHeaderCell>
                                <TableHeaderCell>Updated</TableHeaderCell>
                                <TableHeaderCell>Created</TableHeaderCell>
                                <TableHeaderCell>Edit</TableHeaderCell>
                                <TableHeaderCell>Delete</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <List height={610} itemCount={logs.length} itemSize={45} width="100%" itemData={logs}>
                                {({
                                    index,
                                    style,
                                    data
                                }) => {
                                    return <>
                                        <TableRow aria-rowindex={index + 2} style={style} key={data[index].type}>
                                            <TableCell>{data[index].id}</TableCell>
                                            <TableCell>{data[index].name}</TableCell>
                                            <TableCell>{data[index].timestamp_created}</TableCell>
                                            <TableCell>{data[index].timestamp_updated}</TableCell>
                                            <TableCell>
                                                <TableCellLayout>
                                                    <Button icon={<EditRegular />} as="a" href={"/scheduler/" + data[index].id} >Edit</Button>
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>
                                                    <Button icon={<DeleteRegular />} as="a" onClick={()=>deleteSchedule(data[index].id)}>Delete</Button>
                                                </TableCellLayout>
                                            </TableCell>
                                        </TableRow>
                                    </>;
                                }}
                            </List>
                        </TableBody>

                        <Button as="a" onClick={()=>navigate("/scheduler")} icon={<AddRegular />}>Create</Button>
                    </Table >
                </Card>
            </div>
        </div>
    </>
}

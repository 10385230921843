import * as React from 'react';
import { Text, Card, CardHeader } from "@fluentui/react-components";
import { PersonAccounts20Regular } from '@fluentui/react-icons';
import { ControlPanelNavigation } from './control-panel-navigation';

export const Therapists = () => {
    return <>
        <div className='control-panel-layout'>
            <aside className="sidenav">
                <ControlPanelNavigation />
            </aside>
            <div className="main-cards">
                <Card size='large'>
                    <CardHeader image={<PersonAccounts20Regular />} header={<Text weight="semibold">Therapists</Text>} />
                </Card>
            </div>
        </div>
    </>;
};